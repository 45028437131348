'use client';

import Button, { ButtonProps } from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import { useCallback } from 'react';
import scrollToElement from '@app/utils/scrollToElement';

export interface IProps extends Omit<ButtonProps, 'onClick'|'href'|'external'> {
    targetSelector: string;
}

const GoToSectionButton: React.FC<IProps> = props => {
    const {
        targetSelector,
        ...buttonProps
    } = props;

    const didButtonClicked = useCallback(() => {
        scrollToElement(targetSelector);
    }, [targetSelector]);

    return (
        <Button
            {...buttonProps}
            onClick={didButtonClicked}
        />
    );
};

export default GoToSectionButton;