import { isMobile } from '@frontend/jetlend-web-ui/src/utils/responsive';

/**
 * Функция для плавного и корректного скролла к определенному элементу на странице.
 * @param targetSelector CSS селектор для элемента к которому нужно скроллить
 */
export default function scrollToElement(targetSelector: string) {
    const isMobileSize = isMobile();

    const element = document.querySelector(targetSelector);

    window.scrollTo({
        behavior: 'smooth',
        top: element.getBoundingClientRect().top + window.scrollY - (isMobileSize ? 72 : 82),
    });
}