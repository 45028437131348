import(/* webpackMode: "eager" */ "/app/jetlend_react_lib/packages/jetlend-assets/icons/icon-telegram.svg");
;
import(/* webpackMode: "eager" */ "/app/jetlend_react_lib/packages/jetlend-assets/icons/icon-whatsapp.svg");
;
import(/* webpackMode: "eager" */ "/app/jetlend_react_lib/packages/jetlend-web-ui/src/ui/inputs/Button/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/jetlend_react_lib/packages/jetlend-web-ui/src/ui/TooltipIcon/Tooltip.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/(main)/investor/page.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/InviteFriendBanner/InviteFriendBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/PdfButton/PdfButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/aboutPlatform/DirectorsSection/DirectorsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/aboutPlatform/EmployeeDepartmentBlock/EmployeeDepartmentBlock.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/aboutPlatform/EmployeeSection/EmployeeSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/features/aboutPlatform/FeaturesSection/AdvantagesFeature/AdvantagesFeature.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/features/aboutPlatform/FeaturesSection/BenefitsFeature/BenefitsFeature.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/features/aboutPlatform/FeaturesSection/FeaturesSection.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/aboutPlatform/FeaturesSection/PlatformFeature/PlatformFeature.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/academy/AcademyNewsSection/AcademyNewsSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/features/academy/AcademyNewsSection/AcademyNewsSlider/AcademyNewsSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/borrowers/BecomeBorrowerForm/BecomeBorrowerForm.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/borrowers/BorrowersCalculatorSection/BorrowersCalculatorSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/feedback/FeedbackPlatformItem/FeedbackPlatformItem.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/investments/BecomeInvestmentForm/BecomeInvestmentForm.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/investments/InvestmentsChartSection/InvestmentsChartSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/investments/InvestmentsFeaturesSection/InvestmentsFeaturesSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/investments/InvestmentsMarketForecastChartSection/InvestmentsMarketForecastChartSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/investors/BecomeInvestorForm/BecomeInvestorForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/investors/InvestmentCalculator/InvestmentCalculator.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/investors/InvestorsChartSection/InvestorsChartSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/investors/InvestorsCommonSlideSection/InvestorsCommonSlideSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/investors/InvestorsCommonSlideSection/SlideLink/SlideLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/investors/InvestorsCompaniesSection/InvestorsCompaniesSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/investors/InvestorsFeaturesSection/InvestorsFeaturesSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/investors/InvestorsGeographySection/InvestorsGeographySection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/investors/InvestorsMainSection/InvestorsMainSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/mainPage/MainPageFeaturesSection/MainPageFeaturesSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/news/NewsCard/NewsCard.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/partners/BecomePartnerForm/BecomePartnerForm.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/partners/PartnerCalculatorSection/PartnerCalculatorSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/partners/PartnersFeaturesSection/PartnersFeaturesSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/icons/app-store-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/google-play-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/icon--star.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/investments/contacts--icon.png");
;
import(/* webpackMode: "eager" */ "/app/src/icons/yandex-icon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/sections/BannerSection/DefaultBanner/DefaultBanner.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/sections/BannerSection/LargeBanner/LargeBanner.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/sections/BrokersSection/BrokerCard/BrokerCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/sections/DocumentsSection/DocumentsSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/sections/ExternalNewsSection/ExternalNewsItem.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/ConditionsSection/ConditionsSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/ContactsSection/ContactsSection.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/sections/FaqSection/FaqItem.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/sections/FeedbackSection/FeedbackSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/sections/HowItWorksSection/HowItWorksSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/sections/IndicatorsSection/IndicatorsSection.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/sections/MarkupBlocksSection/MarkupBlocksSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/sections/PartnersSection/PartnersSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/sections/ReportsSection/reports--card-background.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/sections/RollupSection/RollupSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/sections/StatisticsSection/StatisticsSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/MarkupSection/MarkupSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/LinksSection/LinksSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/StatisticsSection/top--badge.svg");
;
import(/* webpackMode: "eager" */ "/app/src/sections/StepsSection/StepsSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/ReportsSection/ReportsCardItem.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/ReportsSection/ReportsSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/FeedbackPlatformsSection/FeedbackPlatformsSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/BrokersSection/BrokersSection.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/sections/StepsSection/StepsSectionBrokerImage/StepsSectionBrokerImage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/sections/StepsSection/StepsSectionBrokersCard/empty_brokers__icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/sections/StepsSection/StepsSectionBrokersCard/StepsSectionBrokersCard.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/sections/StepsSection/StepsSectionStepsCard/StepsSectionStepsCard.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/sections/YoutubeSection/YouTubeItem.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/ui/RatingBadge/RatingBadge.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/ui/Section/Section.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/ui/SectionPart/SectionPart.tsx");
